'use strict';

const tdEventListeners = {
    tdWinDOMContentLoaded(callback){
        window.addEventListener('DOMContentLoaded', callback);
    },
    tdDOMContentLoaded(callback){
        document.addEventListener('DOMContentLoaded', callback);
    },
    tdWinLoad(callback){
        window.addEventListener('load', callback);
    },
    tdLoadstart(obj,callback){
        obj.addEventListener('loadstart', callback);
    },
    tdProgress(obj,callback){
        obj.addEventListener('progress', callback);
    },
    tdLoad(obj,callback){
        obj.addEventListener('load', callback);
    },
    tdLoadend(obj,callback){
        obj.addEventListener('loadend', callback);
    },
    tdError(obj,callback){
        obj.addEventListener('error', callback);
    },
    tdAbort(obj,callback){
        obj.addEventListener('abort', callback);
    }
}

const tdDOMContentLoadedCallbacks = [];
const tdOnDOMContentLoaded = callback => {
    if (document.readyState === 'loading') {
        // add listener on the first call when the document is in loading state
        if (!tdDOMContentLoadedCallbacks.length) {
            tdEventListeners.tdDOMContentLoaded(() => {
                for (const callback of tdDOMContentLoadedCallbacks) {
                    callback();
                }
            });
        }

        tdDOMContentLoadedCallbacks.push(callback);
    } else {
        callback();
    }
};
 
const TdCommon = {  
    evL: tdEventListeners,
    tdDOMContentLoadedCallbacks: tdDOMContentLoadedCallbacks,
    tdOnDOMContentLoaded: tdOnDOMContentLoaded
}

export default TdCommon