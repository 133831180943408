import { isVisible } from './bootstrap/util/index.js';
import EventHandler from './bootstrap/dom/event-handler.js';
import SelectorEngine from './bootstrap/dom/selector-engine.js';
import Modal from './bootstrap/modal.js';
// import { enableDismissTrigger } from './bootstrap/util/component-functions'

// const NAME = 'td-modal'
const DATA_KEY = 'bs.modal';
const EVENT_KEY = `.${DATA_KEY}`;
const DATA_API_KEY = '.data-api';

const EVENT_HIDDEN = `hidden${EVENT_KEY}`;
const EVENT_SHOW = `show${EVENT_KEY}`;
const EVENT_SHOWN = `shown${EVENT_KEY}`;
const EVENT_RESIZE = `resize${EVENT_KEY}`;

const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`;

const OPEN_SELECTOR = '.modal.show';
const SELECTOR_DATA_TOGGLE = '[data-bs-toggle="td-modal"]';

const MODAL_BODY = '.modal-body';

class TdModal extends Modal {
  constructor(element, config) {
    super(element, config);

    this._chk_scrollbar();
    this._event_shown_listener();
  }

  _event_shown_listener(){
    EventHandler.on(this._element, EVENT_SHOWN, () => {
      let $modal_body = this._element.querySelector(MODAL_BODY);
      this._chk_scrollbar_fn(this._element);
      $modal_body.classList.add('td-modal-body-shown');
    });
  }

  _chk_scrollbar(){
    EventHandler.on(window, EVENT_RESIZE, () => {
      this._chk_scrollbar_fn(this._element);
    });
  }

  // adjust padding-right & margin-right depending on scrollbar status
  _chk_scrollbar_fn(_el){
    let $modal_body = _el.querySelector(MODAL_BODY),
        gClientHeight = $modal_body.clientHeight,
        gScrollHeight = $modal_body.scrollHeight;

    if( window.innerWidth >= 768 ){
      if( gClientHeight !=  gScrollHeight ){
        $modal_body.style.setProperty('padding-right',`${Number.parseFloat(32)}px`);
        $modal_body.style.setProperty('margin-right',`${Number.parseFloat(20)}px`);
      }else{
        $modal_body.removeAttribute('style');
      }
    }else{
      if( gClientHeight !=  gScrollHeight ){
        $modal_body.style.setProperty('padding-right',`${Number.parseFloat(8)}px`);
        $modal_body.style.setProperty('margin-right',`${Number.parseFloat(0)}px`);
      }else{
        $modal_body.removeAttribute('style');
      }
    }
  }

}

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, function (event) {
  const target = SelectorEngine.getElementFromSelector(this)

  if (['A', 'AREA'].includes(this.tagName)) {
    event.preventDefault()
  }

  EventHandler.one(target, EVENT_SHOW, showEvent => {
    if (showEvent.defaultPrevented) {
      // only register focus restorer if modal will actually get shown
      return
    }

    EventHandler.one(target, EVENT_HIDDEN, () => {
      if (isVisible(this)) {
        this.focus()
      }
    })
  })

  // avoid conflict when clicking modal toggler while another one is open
  const alreadyOpen = SelectorEngine.findOne(OPEN_SELECTOR)
  if (alreadyOpen) {
    TdModal.getInstance(alreadyOpen).hide()
  }

  const data = TdModal.getOrCreateInstance(target)

  data.toggle(this)
})
// enableDismissTrigger(TdModal);

export default TdModal;