import TdCommon from './td-common.js';
import TdHeader from './td-header.js';
import TdModal from './td-modal.js';
import TdCollapse from './td-collapse.js';

export default {
    TdCommon,
    TdHeader,
    TdModal,
    TdCollapse
}