'use strict';

import TdCommon from './td-common.js';

/**
 * Constants
 */

// class names
const HEADER = "td-header-nav";
const HEADER_NAV_DROPDOWN = "td-header-nav-dropdown";
const HEADER_NAV_DROPDOWN_HOVER = "td-header-nav-dropdown-hover-on";
const HEADER_NAV_DROPDOWN_OPEN = "td-header-nav-dropdown-open";
const HEADER_NAV_DROPDOWN_CLOSE = "td-header-nav-dropdown-close";
const HEADER_NAV_DROPDOWN_ITEMS = "td-header-nav-dropdown-items"; 
const HEADER_NAV_DROPDOWN_ITEMS_OPEN = "td-header-nav-dropdown-items-open"; 
const HEADER_NAV_MAIN_DROPDOWN_ACTIVE = "td-header-nav-main-dropdown-active"; 

// selectors
const $SELECTOR_HEADER = document.querySelectorAll("."+HEADER);


/**
 * Class definition
 */

class TdHeader{
    constructor($obj){
        this.$baseObj = $obj;
    }

    init(){
        //console.log(this.$baseObj);
        this.evt();
    }

    evt(){
        this.$baseObj.forEach(($elHeader, key) => {
            $elHeader.querySelectorAll("."+HEADER_NAV_DROPDOWN).forEach(($el, key) => {

                // dropdown menu toggle on tap on mobile devices, we also want the 'click' event on desktop for JAWS keyboard accessibility
                $el.addEventListener("click", (evt) => { 
                    let targetedEl = evt.currentTarget;
                
                    if ( !targetedEl.classList.contains(HEADER_NAV_DROPDOWN_HOVER) ){
                        this.toggle(targetedEl);
                    }
                });

                $el.addEventListener("keydown", (evt) => { 
                    if ( evt.which === 32 || evt.which === 13 ){ // space key OR enter key
                        this.toggle(evt.currentTarget);
                        //console.log("space key OR enter key");
                    }
                    if ( evt.which === 27 ){ // esc key
                        this.dropdown_close(evt.currentTarget);
                        //console.log("esc key");
                    }
                });

                // dropdown menu on hover
                $el.addEventListener("mouseenter", (evt) => { 
                    let targetedEl = evt.currentTarget;
                    this.close_all_dropdowns();
                    this.dropdown_open(targetedEl);
                    targetedEl.classList?.add(HEADER_NAV_DROPDOWN_HOVER);
                });

                // dropdown menu on hover out
                $el.addEventListener("mouseleave", (evt) => { 
                    let targetedEl = evt.currentTarget;
                    this.dropdown_close(targetedEl);
                    targetedEl.classList?.remove(HEADER_NAV_DROPDOWN_HOVER);
                });

                this.accessibility($el, key);
            });
        });
       
         // setup document click event
        document.addEventListener("click", (evt) => {
            this.on_document_click(evt);
        });
        document.addEventListener("touchstart", (evt) => {
            this.on_document_click(evt);
        });
       
    }

    toggle($dropdown){
        let $el = $dropdown;
        // main nav dropdown
        if ( $el.classList.contains(HEADER_NAV_MAIN_DROPDOWN_ACTIVE) ){
            //console.log("true");
            this.dropdown_close($el);
        }
        else {
            //console.log("false");
            this.close_all_dropdowns();
            this.dropdown_open($el);
        }
    }

    dropdown_open($dropdown, navType = "MAIN"){
        let $el = $dropdown,
        anchorEl = $dropdown.querySelector("a"),
        anchorClasses = anchorEl.classList;

        // main nav dropdown
        if(navType == "MAIN") $el.classList?.add(HEADER_NAV_MAIN_DROPDOWN_ACTIVE);

        // common dropdown
        $el.querySelector("ul."+HEADER_NAV_DROPDOWN_ITEMS).classList?.add(HEADER_NAV_DROPDOWN_ITEMS_OPEN);
        anchorClasses?.remove(HEADER_NAV_DROPDOWN_CLOSE);
        anchorClasses?.add(HEADER_NAV_DROPDOWN_OPEN);
        anchorEl.setAttribute("aria-expanded", "true");
    }
    
    dropdown_close($dropdown, navType = "MAIN"){
        let $el = $dropdown,
        anchorEl = $el.querySelector("a"),
        anchorClasses = anchorEl.classList;

        // main nav dropdown
        if(navType == "MAIN") $el.classList?.remove(HEADER_NAV_MAIN_DROPDOWN_ACTIVE);

        // main nav dropdown
        $dropdown.querySelector("ul."+HEADER_NAV_DROPDOWN_ITEMS).classList?.remove(HEADER_NAV_DROPDOWN_ITEMS_OPEN);
        anchorClasses?.remove(HEADER_NAV_DROPDOWN_OPEN);
        anchorClasses?.add(HEADER_NAV_DROPDOWN_CLOSE);
        anchorEl.setAttribute("aria-expanded", "false");
    }

    close_all_dropdowns(){
        this.$baseObj.forEach(($elHeader, key) => {
            $elHeader.querySelectorAll("."+HEADER_NAV_DROPDOWN).forEach(($el, key) => {
                if ( !$el.classList.contains(HEADER_NAV_DROPDOWN_HOVER) ) this.dropdown_close($el);
            });
        });

    }

    on_document_click(evt){
        if ( !evt.target.closest("."+HEADER_NAV_DROPDOWN) ) this.close_all_dropdowns();
    }

    accessibility($dropdown, key){
        // add aria tag to dropdowns

        let $el = $dropdown,
            $dropdownLink = $el.querySelector("a"),
            $dropdownItems = $el.querySelector("."+HEADER_NAV_DROPDOWN_ITEMS),
            dropdownID = HEADER_NAV_DROPDOWN+"-"+key+Math.floor((Math.random() * 10000) + 1); // unique id to reference the dropdown link
            
        //$dropdownLink.setAttribute('aria-haspopup', 'true');
        $dropdownLink.setAttribute('aria-expanded', 'false');
        $dropdownLink.setAttribute('id', dropdownID); 

        // add aria tag to dropdown content
        $dropdownItems?.setAttribute('aria-labelledby', dropdownID);    // reference dropdown link id to get the label value

        //Hide dropdown menu when tabbing out of last item with 'tab' key
        let $dropdownItems_allItems = $dropdownItems.querySelectorAll('li'),
            $dropdownItems_lastItem = $dropdownItems_allItems[$dropdownItems_allItems.length-1];
        $dropdownItems_lastItem.addEventListener("keydown", (evt) => { 
            if (!evt.shiftKey && evt.which === 9){ // Tab key only, no Shift
                this.close_all_dropdowns();
            }
        });
    
        // Hide dropdown menu when tabbing out of dropdown link with 'shift + tab' key
        $dropdownLink.addEventListener("keydown", (evt) => { 
            if (evt.shiftKey && evt.which === 9){ // Shift key + Tab key
                this.close_all_dropdowns();
            }
        });
    }
}

TdCommon.tdOnDOMContentLoaded(() => {
   (new TdHeader($SELECTOR_HEADER)).init();
});

export default TdHeader;